import { useApolloClient, useLazyQuery } from '@apollo/client';
import { endOfDay } from 'date-fns';
import { useContext, useState } from 'react';
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useAsyncFn } from 'react-use';
import { v4 } from 'uuid';
import FormModalContext from '../../contexts/FormModalContext';
import UserContext from '../../contexts/UserContext';
import {
	Ad_Ref_ListTenderTypesDocument,
	C_AcctSchemaForCurrencyUseDocument,
	C_BankAccountForPaymentsDocument,
	C_BPartnerDisplayForDebtFragmentDoc,
	C_BPartnerForDebtPaymentDocument,
	C_BPartnerForDebtPaymentQuery,
	C_DocTypeForFormsDocument,
	C_PaymentForDebtEditingDocument,
	C_PaymentForDebtEditingQuery,
	C_PaymentForDebtProcessingDocument,
	C_PaymentInput,
	ReportOutput,
} from '../../graphql/__generated__/graphql';
import useActionPrivileges from '../../hooks/useActionPrivileges';
import useConfirmRefresh from '../../hooks/useConfirmRefresh';
import useSuspenseAsync from '../../hooks/useSuspenseAsync';
import useSuspenseGraphQLDocumentActionInformation from '../../hooks/useSuspenseGraphQLDocumentActionInformation';
import useSuspenseGraphQLProcessAccess from '../../hooks/useSuspenseGraphQLProcessAccess';
import {
	BaseMetadataDB,
	BusinessPartner,
	BusinessPartnerDB,
	DBFilter,
	DEBT_PAYMENT_RECEIPT,
	DocAction,
	referenceUuids,
} from '../../models';
import { documentBaseType } from '../../models/DocumentType';
import { ReferenceListDB } from '../../models/ReferenceList';
import { pageUuid } from '../../services/AuthService';
import EntityFormProperties from '../../types/EntityFormProperties';
import { exception } from '../../utils/analytics';
import { getPatientSearchFilter } from '../../utils/FilterUtil';
import { uiText } from '../../utils/Language';
import { getDocumentBaseTypeFilter } from '../../utils/ModelUtils';
import { generateReportWithGivenParameterValue } from '../../utils/ReportUtil';
import { isEntityCompleted, isEntityDrafted, isEntityVoided } from '../../utils/StatusUtil';
import BandaDatePicker from '../banda-date-picker/BandaDatePicker';
import EntityLookupGraphQL from '../entity-lookup/EntityLookupGraphQL';
import FormatNumberInput from '../format-number-input/FormatNumberInput';
import { withFormModalSuspenseWrapper } from '../HOCs/withFormModalSuspenseWrapper';
import Layout from '../Layout/Layout';
import LoadSpinner from '../LoadSpinner/LoadSpinner';
import ReceiptPrint from '../Reports/ReceiptPrint';

export type PaymentFormValues = {
	UU: string;
	C_BPartner: { UU: string; TotalOpenBalance: number };
	DateTrx: Date;
	Description: string;
	PayAmt: number | null;
	TenderTypeUU: string;
	submitEvent: '' | 'complete' | 'void';
};

export type PaymentFormProps = {
	businessPartner?: BusinessPartner;
} & EntityFormProperties;

const fetchReceiptDocumentTypeArguments = [documentBaseType.ARReceipt, null, null, true, false, false] as const;
const getTitle = (uuid?: string) => (uuid ? uiText.payment.title.UPDATE : uiText.payment.title.NEW);

const convertToFormFields = (
	initialData?: C_PaymentForDebtEditingQuery['C_Payment'],
	initialPatient?: C_BPartnerForDebtPaymentQuery['C_BPartnerGet']['Results'][0],
): PaymentFormValues => {
	if (!initialData) {
		return {
			UU: v4(),
			C_BPartner: initialPatient
				? { UU: initialPatient.UU, TotalOpenBalance: initialPatient.TotalOpenBalance || 0 }
				: { UU: '', TotalOpenBalance: 0 },
			DateTrx: new Date(),
			Description: '',
			TenderTypeUU: '',
			PayAmt: null,
			submitEvent: '',
		};
	}
	return {
		UU: initialData.UU,
		C_BPartner: { UU: initialData.C_BPartner.UU, TotalOpenBalance: initialData.C_BPartner.TotalOpenBalance || 0 },
		DateTrx: new Date(initialData.DateTrx),
		Description: initialData.Description || '',
		TenderTypeUU: initialData.TenderType.UU,
		PayAmt: initialData.PayAmt,
		submitEvent: '',
	};
};

function PaymentForm({ uuid, onFinish, renderAsModal, businessPartner }: PaymentFormProps) {
	const graphqlClient = useApolloClient();
	const { organization } = useContext(UserContext);
	const canPrintReceipt = useSuspenseGraphQLProcessAccess(DEBT_PAYMENT_RECEIPT);

	const { data: [data, receiptDocumentType, bankAccounts, accountSchema, paymentTypes, initialPatient] = [] } =
		useSuspenseAsync(uuid || 'add-payment', async () =>
			Promise.all([
				uuid
					? graphqlClient
							.query({
								query: C_PaymentForDebtEditingDocument,
								variables: { UU: uuid },
								fetchPolicy: 'network-only',
							})
							.then((response) => response.data.C_Payment)
					: undefined,
				graphqlClient
					.query({
						query: C_DocTypeForFormsDocument,
						variables: { Filter: getDocumentBaseTypeFilter(...fetchReceiptDocumentTypeArguments).toString() },
						fetchPolicy: 'cache-first',
					})
					.then((response) => response.data.C_DocTypeGet.Results[0]),
				graphqlClient
					.query({
						query: C_BankAccountForPaymentsDocument,
						variables: {
							Sort: JSON.stringify([['created', 'asc']]),
							Filter: DBFilter<BaseMetadataDB>()
								.nested('ad_org')
								.property('ad_org_uu')
								.equals(organization.uuid)
								.up()
								.toString(),
						},
						fetchPolicy: 'cache-first',
					})
					.then((response) => response.data.C_BankAccountGet.Results),
				graphqlClient
					.query({ query: C_AcctSchemaForCurrencyUseDocument, fetchPolicy: 'cache-first' })
					.then((response) => response.data.C_AcctSchemaGet.Results[0]),
				graphqlClient
					.query({
						query: Ad_Ref_ListTenderTypesDocument,
						variables: {
							Filter: DBFilter<ReferenceListDB>()
								.nested('ad_reference')
								.property('ad_reference_uu')
								.equals(referenceUuids.TENDER_TYPES)
								.up()
								.toString(),
						},
						fetchPolicy: 'cache-first',
					})
					.then((response) => response.data.AD_Ref_ListGet.Results),
				!uuid && businessPartner?.uuid
					? graphqlClient
							.query({
								query: C_BPartnerForDebtPaymentDocument,
								variables: {
									Filter: DBFilter<BusinessPartnerDB>()
										.property('c_bpartner_uu')
										.equals(businessPartner.uuid)
										.toString(),
								},
							})
							.then((response) => response.data.C_BPartnerGet.Results[0])
					: undefined,
			]),
		);

	const { t } = useTranslation();
	const [dataToUse, setDataToUse] = useState(data);
	const formMethods = useForm<PaymentFormValues>({ defaultValues: convertToFormFields(dataToUse, initialPatient) });
	const isDataReadOnly = isEntityCompleted(dataToUse) || isEntityVoided(dataToUse);
	const { disableWrite } = useActionPrivileges(pageUuid.PAYMENTS);
	const { canVoidDocument, voidDocumentAction } = useSuspenseGraphQLDocumentActionInformation(
		documentBaseType.MaterialMovement,
		dataToUse?.DocStatus.Value,
	);
	const title = dataToUse
		? isEntityCompleted(dataToUse) || isEntityVoided(dataToUse)
			? uiText.payment.title.UPDATE
			: uiText.payment.title.UPDATE
		: uiText.payment.title.NEW;

	const paymentAmount = formMethods.watch('PayAmt');
	const patient = graphqlClient.readFragment({
		id: formMethods.watch('C_BPartner.UU'),
		fragment: C_BPartnerDisplayForDebtFragmentDoc,
	});

	useConfirmRefresh(formMethods.formState?.isDirty);

	const [onSearchPatient, { data: patientOptions, loading: areLoadingPatients }] = useLazyQuery(
		C_BPartnerForDebtPaymentDocument,
		{ fetchPolicy: 'network-only' },
	);

	const [{ value: printReceiptUrl, loading: arePrintingReceipt }, generateReceipt] = useAsyncFn(
		async (UU: string) =>
			URL.createObjectURL(
				await generateReportWithGivenParameterValue(graphqlClient, DEBT_PAYMENT_RECEIPT, UU, ReportOutput.Pdf),
			),
		[graphqlClient],
	);

	const { dataWasSaved, savedData, wasDataSaved } = useContext(FormModalContext);

	const { reset } = formMethods;
	const [{ loading: areProcessingDocument }, onSubmit] = useAsyncFn<SubmitHandler<PaymentFormValues>>(
		async (formData) => {
			const formAction = formData.submitEvent;
			let receiptDocumentTypeToUse = receiptDocumentType;
			if (!receiptDocumentTypeToUse) {
				try {
					receiptDocumentTypeToUse = (
						await graphqlClient.query({
							query: C_DocTypeForFormsDocument,
							variables: { Filter: getDocumentBaseTypeFilter(...fetchReceiptDocumentTypeArguments).toString() },
							fetchPolicy: 'network-only',
						})
					).data.C_DocTypeGet.Results[0];
				} catch (error) {
					exception({ description: 'Could not load A/R receipt document type: ' + error });
					toast.error(t(uiText.error.PLEASE_TRY_AGAIN));
					return;
				}
			}
			let bankAccountUU = bankAccounts?.filter((bankAccount) => bankAccount.IsDefault)[0]?.UU;
			if (!bankAccountUU) {
				bankAccountUU = bankAccounts?.[0]?.UU;
			}
			const payment: C_PaymentInput = {
				UU: formData.UU,
				BH_tender_amount: formData.PayAmt,
				C_BPartner: { UU: formData.C_BPartner.UU },
				C_BankAccount: bankAccountUU ? { UU: bankAccountUU } : undefined,
				C_Currency: accountSchema?.C_Currency.UU ? { UU: accountSchema.C_Currency.UU } : undefined,
				C_DocType: { UU: receiptDocumentTypeToUse.UU },
				DateAcct: formData.DateTrx.getTime(),
				DateTrx: formData.DateTrx.getTime(),
				Description: formData.Description || null,
				IsReceipt: receiptDocumentTypeToUse.IsSOTrx,
				PayAmt:
					(formData.PayAmt || 0) > (patient?.TotalOpenBalance || 0)
						? patient?.TotalOpenBalance || 0
						: formData.PayAmt || 0,
				TenderType: { UU: formData.TenderTypeUU },
			};

			let processType = formAction === 'complete' ? DocAction.COMPLETE : (voidDocumentAction as string);

			await graphqlClient.mutate({
				mutation: C_PaymentForDebtProcessingDocument,
				variables: { C_Payment: payment, C_Payment_UU: payment.UU!, DocumentAction: processType },
			});
			const newData = (
				await graphqlClient.query({
					query: C_PaymentForDebtEditingDocument,
					variables: { UU: payment.UU! },
					fetchPolicy: 'network-only',
				})
			).data.C_Payment!;
			reset(convertToFormFields(newData));
			setDataToUse(newData);
			dataWasSaved(newData.UU);
			toast.success(t(uiText.payment.PROCESS_SUCCESS));
		},
		[voidDocumentAction, generateReceipt, graphqlClient, reset, receiptDocumentType, dataWasSaved, patient],
	);

	const inputs = (
		<FormProvider {...formMethods}>
			<Form autoComplete="off" onSubmit={formMethods.handleSubmit(onSubmit)} className="px-0">
				<Form.Control {...formMethods.register('UU')} hidden />
				<fieldset disabled={disableWrite || isDataReadOnly}>
					<Form.Group as={Row} className="mb-3" controlId="transactionDate">
						<Col xs={1} className="d-flex align-items-center">
							<Form.Label column>{t(uiText.payment.TRANSACTION_DATE)}</Form.Label>
						</Col>
						<Col xs={8} className="d-flex align-items-center">
							<Controller<PaymentFormValues, 'DateTrx'>
								name="DateTrx"
								rules={{ required: true }}
								render={({ field }) => (
									<BandaDatePicker maxDate={endOfDay(new Date())} {...field} value={undefined} selected={field.value} />
								)}
							/>
							{formMethods.formState.errors?.DateTrx && (
								<span className="text-danger">{t(uiText.payment.error.MISSING_TRANSACTION_DATE)}</span>
							)}
						</Col>
					</Form.Group>
					<Form.Group as={Row} className="mb-3" controlId="businessPartner">
						<Col xs={1} className="d-flex align-items-center">
							<Form.Label column>{t(uiText.patient.LABEL)}</Form.Label>
						</Col>
						<Col xs={8} className="d-flex align-items-center">
							<EntityLookupGraphQL<PaymentFormValues, 'C_BPartner'>
								className="w-100"
								name="C_BPartner"
								rules={{ required: true }}
								isLoading={areLoadingPatients}
								id="businessPartner"
								emptyLabel={t(uiText.patient.search.EMPTY)}
								labelKey={(data) =>
									graphqlClient.readFragment({
										id: data.UU,
										fragment: C_BPartnerDisplayForDebtFragmentDoc,
									})?.Name || ''
								}
								placeholder={t(uiText.patient.search.PLACEHOLDER)}
								promptText={t(uiText.patient.search.SEARCHING)}
								searchText={t(uiText.patient.search.SEARCHING)}
								options={patientOptions?.C_BPartnerGet.Results || []}
								onSearch={(query) =>
									onSearchPatient({
										variables: {
											Filter: getPatientSearchFilter(query).toString(),
										},
									})
								}
								disabled={isDataReadOnly}
							/>
							{formMethods.formState.errors?.C_BPartner && (
								<span className="text-danger">{t(uiText.patient.name.VALIDATION)}</span>
							)}
						</Col>
					</Form.Group>
					<Form.Group as={Row} className="mb-3" controlId="TotalOpenBalance">
						<Col xs={1} className="d-flex align-items-center">
							<Form.Label column>{t(uiText.patient.OPEN_BALANCE)}</Form.Label>
						</Col>
						<Col xs={8} className="d-flex align-items-center">
							<FormatNumberInput className="text-start" readOnly={true} value={patient?.TotalOpenBalance} />
						</Col>
					</Form.Group>
					<Form.Group as={Row} className="mb-3" controlId="paymentType">
						<Col xs={1} className="d-flex align-items-center">
							<Form.Label column>{t(uiText.payment.tenderType.LABEL)}</Form.Label>
						</Col>
						<Col xs={8} className="d-flex align-items-center">
							<Form.Select {...formMethods.register('TenderTypeUU', { required: true })}>
								{paymentTypes
									?.filter((paymentType) => isEntityCompleted(dataToUse) || paymentType.IsActive)
									.map((paymentType) => (
										<option key={paymentType.UU} value={paymentType.UU}>
											{paymentType.Name}
										</option>
									))}
							</Form.Select>
							{formMethods.formState.errors?.TenderTypeUU && (
								<span className="text-danger">{t(uiText.payment.error.MISSING_TYPE)}</span>
							)}
						</Col>
					</Form.Group>
					<Form.Group as={Row} className="mb-3" controlId="payAmount">
						<Col xs={1} className="d-flex align-items-center">
							<Form.Label column>{t(uiText.payment.PAYMENT_AMOUNT)}</Form.Label>
						</Col>
						<Col xs={8} className="d-flex align-items-center">
							<Controller<PaymentFormValues, 'PayAmt'>
								name="PayAmt"
								rules={{ validate: (value) => value !== 0 }}
								render={({ field }) => <FormatNumberInput min={0} {...field} />}
							/>
						</Col>
					</Form.Group>
					<Form.Group as={Row} className="mb-3" controlId="description">
						<Col xs={1} className="d-flex align-items-center">
							<Form.Label column>{t(uiText.payment.description.LABEL)}</Form.Label>
						</Col>
						<Col xs={8} className="d-flex align-items-center">
							<Form.Control
								as="textarea"
								rows={4}
								placeholder={t(uiText.service.description.PLACEHOLDER)}
								{...formMethods.register('Description')}
							/>
						</Col>
					</Form.Group>
					{!dataToUse || isEntityDrafted(dataToUse) ? (
						<Form.Group as={Row} className="mb-3" controlId="change">
							<Col xs={1} className="d-flex align-items-center">
								<Form.Label column>{t(uiText.payment.CHANGE)}</Form.Label>
							</Col>
							<Col xs={8} className="d-flex align-items-center">
								<FormatNumberInput
									min={0}
									value={
										(paymentAmount || 0) - (patient?.TotalOpenBalance || 0) > 0
											? (paymentAmount || 0) - (patient?.TotalOpenBalance || 0)
											: 0
									}
									readOnly={true}
								/>
							</Col>
						</Form.Group>
					) : null}
				</fieldset>
				<Form.Control {...formMethods.register('submitEvent')} defaultValue={''} hidden />
			</Form>
		</FormProvider>
	);

	const buttons = (
		<Row className={`${renderAsModal ? '' : 'my-4'}`}>
			<Col xs="auto" className="me-auto">
				<Button variant="danger" onClick={() => (wasDataSaved ? onFinish(true, savedData) : onFinish(false))}>
					{isDataReadOnly ? t(uiText.payment.button.BACK) : t(uiText.payment.button.CANCEL)}
				</Button>
			</Col>
			{!dataToUse || isEntityDrafted(dataToUse) ? (
				<Col xs="auto">
					<Button
						type="submit"
						variant="success"
						onClick={() => {
							formMethods.setValue('submitEvent', 'complete');
							formMethods.handleSubmit(onSubmit)();
						}}
					>
						{t(uiText.payment.action.PROCESS)}
					</Button>
				</Col>
			) : null}
			{isEntityCompleted(dataToUse) ? (
				<>
					{canVoidDocument ? (
						<Col xs="auto">
							<Button
								type="submit"
								variant="danger"
								onClick={() => {
									formMethods.setValue('submitEvent', 'void');
									formMethods.handleSubmit(onSubmit)();
								}}
							>
								{t(uiText.payment.button.VOID_PAYMENT)}
							</Button>
						</Col>
					) : null}
					{canPrintReceipt ? (
						<Col xs="auto">
							<Button type="button" variant="primary" onClick={() => generateReceipt(formMethods.getValues('UU'))}>
								{t(uiText.payment.action.PRINT_RECEIPT)}
							</Button>
						</Col>
					) : null}
				</>
			) : null}
			{!renderAsModal && <Col xs={3} />}
		</Row>
	);

	return (
		<>
			{printReceiptUrl && <ReceiptPrint id={'serviceDebtReceipt'} url={printReceiptUrl} />}
			{renderAsModal ? (
				<>
					<Modal.Header closeButton>
						<Modal.Title>{t(title)}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{arePrintingReceipt || areProcessingDocument ? (
							<LoadSpinner inline title={t(uiText.payment.PROCESSING)} />
						) : (
							inputs
						)}
					</Modal.Body>
					{!(arePrintingReceipt || areProcessingDocument) ? (
						<Modal.Footer>
							<div className="w-100">{buttons}</div>
						</Modal.Footer>
					) : null}
				</>
			) : (
				<>
					<Layout.Header>
						<Layout.Title title={t(title)} />
						<Layout.Menu />
					</Layout.Header>
					<Layout.Body>
						{arePrintingReceipt || areProcessingDocument ? (
							<LoadSpinner title={t(uiText.payment.PROCESSING)} />
						) : (
							<div className="me-n2_5 pb-0_5 bg-white">
								<Card className="bh-card">
									<Card.Body>
										{inputs}
										{buttons}
									</Card.Body>
								</Card>
							</div>
						)}
					</Layout.Body>
				</>
			)}
		</>
	);
}

export default withFormModalSuspenseWrapper<PaymentFormProps>({
	loadingLabel: uiText.payment.loading.LOADING,
	getTitle,
})(PaymentForm);
