import { ApolloClient, useApolloClient } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sortBy } from 'lodash';
import { useEffect } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import {
	Bh_Bp_General_Payer_InfoInput,
	Bh_Bp_Payer_InfoInput,
	C_BPartnerForPatientEditingQuery,
	C_BPartnerForPatientNeedingInsurerOrDonorDocument,
	C_BPartnerForPatientNeedingInsurerOrDonorQueryVariables,
	C_BPartnerPayerInformationFieldListFragmentDoc,
} from '../../graphql/__generated__/graphql';
import { BusinessPartnerDB, businessPartnerGroupInsurerAndDonorFilter, DBFilter, Filter, Paging } from '../../models';
import { IS_ACTIVE } from '../../utils/CommonFilters';
import { uiText } from '../../utils/Language';
import { PatientFormValues } from './PatientForm';
import PayerInformationInput from './PayerInformationInput';

export type AdditionalInformationForVisitFormValues = {
	BH_BP_Payer_Info_BPList: Array<{
		UU: string;
		BH_BP_General_Payer_InfoList: Array<{ UU: string; BH_Payer_Info_Fld: { UU: string }; Name: string | null }>;
		BH_Payer: { UU: string };
	}>;
	addNewBusinessPartnerPayer: { UU: string };
};

export const convertToAdditionalInformationForVisitFormFields: (
	initialData?: C_BPartnerForPatientEditingQuery['C_BPartner'],
) => AdditionalInformationForVisitFormValues = (initialData) => {
	if (!initialData) {
		return { BH_BP_Payer_Info_BPList: [], addNewBusinessPartnerPayer: { UU: '' } };
	}
	return {
		BH_BP_Payer_Info_BPList:
			initialData.BH_BP_Payer_Info_BPList?.map((payerInformation) => ({
				UU: payerInformation.UU,
				BH_BP_General_Payer_InfoList:
					payerInformation.BH_BP_General_Payer_InfoList?.map((businessPartnerGeneralPayerInformation) => ({
						UU: businessPartnerGeneralPayerInformation.UU,
						BH_Payer_Info_Fld: { UU: businessPartnerGeneralPayerInformation.BH_Payer_Info_Fld.UU },
						Name: businessPartnerGeneralPayerInformation.Name,
					})) || [],
				BH_Payer: { UU: payerInformation.BH_Payer.UU },
			})) || [],
		addNewBusinessPartnerPayer: { UU: '' },
	};
};

const insurersAndDonorsThatCanTakePatientFilledDataQueryVariables: C_BPartnerForPatientNeedingInsurerOrDonorQueryVariables =
	{
		Page: Paging.ALL.page,
		Size: Paging.ALL.size,
		Filter: DBFilter<BusinessPartnerDB>()
			.nested('c_bp_group')
			.and(businessPartnerGroupInsurerAndDonorFilter())
			.up()
			.and(IS_ACTIVE as unknown as Filter<BusinessPartnerDB>)
			.nested('bh_payer_info_fld')
			.property('shouldFillFromPatient')
			.equals(true)
			.up()
			.toString(),
	};
export const primeAdditionalInformationForVisitData = (graphqlClient: ApolloClient<object>) =>
	Promise.all([
		graphqlClient.query({
			query: C_BPartnerForPatientNeedingInsurerOrDonorDocument,
			variables: insurersAndDonorsThatCanTakePatientFilledDataQueryVariables,
			fetchPolicy: 'network-only',
		}),
	]);

export const constructAdditionalInformationForVisitFormData: (
	formData: PatientFormValues,
) => [Bh_Bp_Payer_InfoInput[], Bh_Bp_General_Payer_InfoInput[]] = (formData) => {
	return [
		formData.BH_BP_Payer_Info_BPList.map(
			(payerInformation): Bh_Bp_Payer_InfoInput => ({
				UU: payerInformation.UU,
				BH_Payer: { UU: payerInformation.BH_Payer.UU },
				C_BPartner: { UU: formData.UU },
				IsActive: true,
			}),
		),
		formData.BH_BP_Payer_Info_BPList.flatMap((payerInformation) =>
			payerInformation.BH_BP_General_Payer_InfoList.map(
				(generalPayerInformation): Bh_Bp_General_Payer_InfoInput => ({
					UU: generalPayerInformation.UU,
					BH_BP_Payer_Info: { UU: payerInformation.UU },
					BH_Payer_Info_Fld: { UU: generalPayerInformation.BH_Payer_Info_Fld.UU },
					IsActive: true,
					Name: generalPayerInformation.Name,
				}),
			),
		),
	];
};

const AdditionalInformationForVisit = () => {
	const { t } = useTranslation();
	const {
		register,
		setValue,
		formState: { errors },
	} = useFormContext<AdditionalInformationForVisitFormValues>();
	const graphqlClient = useApolloClient();
	const addNewBusinessPartnerPayerUU = useWatch<
		AdditionalInformationForVisitFormValues,
		'addNewBusinessPartnerPayer.UU'
	>({
		name: 'addNewBusinessPartnerPayer.UU',
	});
	const { fields, append, remove } = useFieldArray<
		AdditionalInformationForVisitFormValues,
		'BH_BP_Payer_Info_BPList',
		'UU'
	>({
		name: 'BH_BP_Payer_Info_BPList',
	});
	const businessPartnerPayerInformationList = useWatch<
		AdditionalInformationForVisitFormValues,
		'BH_BP_Payer_Info_BPList'
	>({
		name: 'BH_BP_Payer_Info_BPList',
	});

	const insurersAndDonorsThatCanTakePatientFilledData = graphqlClient.readQuery({
		query: C_BPartnerForPatientNeedingInsurerOrDonorDocument,
		variables: insurersAndDonorsThatCanTakePatientFilledDataQueryVariables,
	})?.C_BPartnerGet.Results;

	// This handles adding a new row if the user started typing a new value
	useEffect(() => {
		if (addNewBusinessPartnerPayerUU) {
			append({
				UU: v4(),
				BH_BP_General_Payer_InfoList: sortBy(
					graphqlClient.readFragment({
						id: addNewBusinessPartnerPayerUU,
						fragment: C_BPartnerPayerInformationFieldListFragmentDoc,
					})?.BH_Payer_Info_FldList || [],
					'Line',
				)
					.filter((payerInformationField) => payerInformationField.IsActive && payerInformationField.BH_FillFromPatient)
					.map((payerInformationField) => ({
						UU: v4(),
						BH_Payer_Info_Fld: { UU: payerInformationField.UU },
						Name: null,
					})),
				BH_Payer: { UU: addNewBusinessPartnerPayerUU },
			});
			setValue('addNewBusinessPartnerPayer.UU', '');
		}
	}, [addNewBusinessPartnerPayerUU, append, setValue, graphqlClient]);

	return (
		(insurersAndDonorsThatCanTakePatientFilledData?.length && (
			<Card className="bh-card">
				<Card.Header className="fw-bold h5">{t(uiText.patient.additionalInformation.TITLE)}</Card.Header>
				<Card.Body>
					<table className="bh-table--form">
						<thead>
							<tr>
								<th className="w-27">{t(uiText.patient.additionalInformation.NON_PATIENT_PAYMENT)}</th>
								<th>{t(uiText.patient.additionalInformation.PATIENT_INFORMATION)}</th>
								<th className="w-6">{t(uiText.patient.button.DELETE)}</th>
							</tr>
						</thead>
						<tbody>
							{fields.map((field, index) => {
								const selectedInsurerOrDonor = graphqlClient.readFragment({
									id: field.BH_Payer.UU,
									fragment: C_BPartnerPayerInformationFieldListFragmentDoc,
								});
								const fieldName = `BH_BP_Payer_Info_BPList.${index}`;
								// If there isn't an associated insurer or donor, it means the insurer or donor was disabled
								// and we shouldn't show this row
								return (
									selectedInsurerOrDonor && (
										<tr key={field.UU}>
											<td className="align-middle ps-2_5">
												<input
													type="hidden"
													{...register(`${fieldName}.UU` as 'BH_BP_Payer_Info_BPList.0.UU')}
													defaultValue={field.UU}
												/>
												<input
													type="hidden"
													{...register(`${fieldName}.BH_Payer.UU` as 'BH_BP_Payer_Info_BPList.0.BH_Payer.UU')}
												/>
												{selectedInsurerOrDonor.Name}
											</td>
											<td>
												<Row>
													{sortBy(
														selectedInsurerOrDonor.BH_Payer_Info_FldList?.filter(
															(payerInformationField) =>
																payerInformationField.BH_FillFromPatient && payerInformationField.IsActive,
														) || [],
														'Line',
													).map((payerInformationField, payerInformationFieldIndex) => {
														let businessPartnerPayerInformationIndex = field.BH_BP_General_Payer_InfoList.findIndex(
															(businessPartnerGeneralPayerInformation) =>
																businessPartnerGeneralPayerInformation.BH_Payer_Info_Fld.UU ===
																payerInformationField.UU,
														);
														let businessPartnerGeneralPayerInformation =
															field.BH_BP_General_Payer_InfoList[businessPartnerPayerInformationIndex];
														//
														if (businessPartnerPayerInformationIndex === -1) {
															businessPartnerPayerInformationIndex = payerInformationFieldIndex;
															businessPartnerGeneralPayerInformation =
																field.BH_BP_General_Payer_InfoList[payerInformationFieldIndex];
														}
														const subFieldName = `${fieldName}.BH_BP_General_Payer_InfoList.${businessPartnerPayerInformationIndex}`;
														return (
															<Col xs={3} key={businessPartnerGeneralPayerInformation.UU}>
																<input
																	type="hidden"
																	{...register(
																		`${subFieldName}.UU` as 'BH_BP_Payer_Info_BPList.0.BH_BP_General_Payer_InfoList.0.UU',
																	)}
																/>
																<input
																	type="hidden"
																	{...register(
																		`${subFieldName}.BH_Payer_Info_Fld.UU` as 'BH_BP_Payer_Info_BPList.0.BH_BP_General_Payer_InfoList.0.BH_Payer_Info_Fld.UU',
																	)}
																/>
																<PayerInformationInput
																	payerInformationField={payerInformationField}
																	label={t(uiText.patient.additionalInformation.PATIENT_INFORMATION)}
																	inputPlaceholder={`${t(uiText.patient.additionalInformation.ENTER_PREFIX)} ${
																		payerInformationField.Name
																	}`}
																	selectPlaceholder={`${t(uiText.patient.additionalInformation.SELECT_PREFIX)} ${
																		payerInformationField.Name
																	}`}
																	registerReturn={register(
																		`${subFieldName}.Name` as 'BH_BP_Payer_Info_BPList.0.BH_BP_General_Payer_InfoList.0.Name',
																		{ required: true },
																	)}
																/>
															</Col>
														);
													})}
												</Row>
											</td>
											<td className="align-middle text-center">
												<button
													aria-label={t(uiText.patient.button.DELETE)}
													type="button"
													className="btn p-0 w-100"
													tabIndex={-1}
													onClick={() => remove(index)}
												>
													<FontAwesomeIcon icon="trash" className="border-0" />
												</button>
											</td>
										</tr>
									)
								);
							})}
						</tbody>
						<tbody>
							<tr>
								<td>
									<Form.Select
										aria-label={t(uiText.patient.additionalInformation.NON_PATIENT_PAYMENT)}
										{...register('addNewBusinessPartnerPayer.UU')}
									>
										<option value=""></option>
										{insurersAndDonorsThatCanTakePatientFilledData
											.filter(
												(insurerOrDonorToSelect) =>
													!businessPartnerPayerInformationList.some(
														(currentBusinessPartnerCharge) =>
															currentBusinessPartnerCharge.BH_Payer.UU === insurerOrDonorToSelect.UU,
													),
											)
											.map((insurerOrDonor) => (
												<option key={insurerOrDonor.UU} value={insurerOrDonor.UU}>
													{insurerOrDonor.Name}
												</option>
											))}
									</Form.Select>
								</td>
								<td></td>
								<td></td>
							</tr>
						</tbody>
					</table>
					{!!errors.BH_BP_Payer_Info_BPList?.length && (
						<div className="text-danger">{t(uiText.patient.validationMessages.REQUIRE_INFO_TO_BE_ENTERED)}</div>
					)}
				</Card.Body>
			</Card>
		)) ||
		null
	);
};

export default AdditionalInformationForVisit;
