import { ApolloClient, useApolloClient, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-use';
import {
	Ad_Ref_ListPatientTypesForVisitsDocument,
	Ad_Ref_ListPatientTypesForVisitsQueryVariables,
	Ad_Ref_ListReferralsForVisitsDocument,
	Ad_Ref_ListReferralsForVisitsQueryVariables,
	Ad_UserForVisitCliniciansDocument,
	Ad_UserForVisitCliniciansQueryVariables,
	Bh_VisitForVisitHistoryInitialDocument,
	C_BPartnerForVisitHistoryDocument,
} from '../../graphql/__generated__/graphql';
import { referenceUuids, roleUuid, UserDB } from '../../models';
import DBFilter from '../../models/DBFilter';
import DocumentStatus from '../../models/DocumentStatus';
import { ReferenceListDB } from '../../models/ReferenceList';
import { VisitDB } from '../../models/Visit';
import { uiText } from '../../utils/Language';
import { VisitFormValues } from './VisitForm';
import VisitHistoryLine from './VisitHistoryLine';

const cliniciansVariables: Ad_UserForVisitCliniciansQueryVariables = {
	Filter: DBFilter<UserDB>()
		.nested('ad_user_roles.ad_role')
		.nested('ad_role_included.ad_role::included_role_id')
		.property('ad_role_uu')
		.isIn([roleUuid.CLINICIAN_NURSE_ADVANCED, roleUuid.CLINICIAN_NURSE_BASIC])
		.up()
		.up()
		.toString(),
} as const;
const referralsVariables: Ad_Ref_ListReferralsForVisitsQueryVariables = {
	Filter: DBFilter<ReferenceListDB>()
		.nested('ad_reference')
		.property('ad_reference_uu')
		.equals(referenceUuids.REFERRALS)
		.up()
		.toString(),
} as const;
const patientTypeVariables: Ad_Ref_ListPatientTypesForVisitsQueryVariables = {
	Filter: DBFilter<ReferenceListDB>()
		.nested('ad_reference')
		.property('ad_reference_uu')
		.equals(referenceUuids.PATIENT_TYPES)
		.up()
		.toString(),
} as const;
export const primeVisitHistoryData = (graphqlClient: ApolloClient<object>) =>
	Promise.all([
		graphqlClient.query({
			query: Ad_UserForVisitCliniciansDocument,
			variables: cliniciansVariables,
			fetchPolicy: 'network-only',
		}),
		graphqlClient.query({
			query: Ad_Ref_ListReferralsForVisitsDocument,
			variables: referralsVariables,
			fetchPolicy: 'cache-first',
		}),
		graphqlClient.query({
			query: Ad_Ref_ListPatientTypesForVisitsDocument,
			variables: patientTypeVariables,
			fetchPolicy: 'cache-first',
		}),
	]);

type VisitHistoryProps = { onClose: () => void };

const VisitHistory = ({ onClose }: VisitHistoryProps) => {
	const { t } = useTranslation();
	const graphqlClient = useApolloClient();

	const patientUU = useWatch<VisitFormValues, 'Patient.UU'>({ name: 'Patient.UU' });
	const visitUuid = useWatch<VisitFormValues, 'UU'>({ name: 'UU' });
	const isNewVisit = useWatch<VisitFormValues, 'isNew'>({ name: 'isNew' });
	const clinicians = useMemo(
		() =>
			graphqlClient.readQuery({
				query: Ad_UserForVisitCliniciansDocument,
				variables: cliniciansVariables,
			})?.AD_UserGet.Results,
		[graphqlClient],
	);
	const referrals = useMemo(
		() =>
			graphqlClient.readQuery({
				query: Ad_Ref_ListReferralsForVisitsDocument,
				variables: referralsVariables,
			})?.AD_Ref_ListGet.Results,
		[graphqlClient],
	);
	const patientTypes = useMemo(
		() =>
			graphqlClient.readQuery({
				query: Ad_Ref_ListPatientTypesForVisitsDocument,
				variables: patientTypeVariables,
			})?.AD_Ref_ListGet.Results,
		[graphqlClient],
	);

	const initialVisitCountToFetch = 3;
	const visitCountModifier = isNewVisit === 'true' ? 0 : 1;
	const { value: patientVisitDetails, loading: areLoadingVisits } = useAsync(async () => {
		if (!patientUU) {
			return { visits: [], totalVisits: 0 };
		}
		const filter = DBFilter<VisitDB>()
			.and(DBFilter<VisitDB>().nested('c_bpartner::patient_id').property('c_bpartner_uu').equals(patientUU).up())
			.and(DBFilter<VisitDB>().property('bh_visit_uu').doesNotEqual(visitUuid))
			.and(DBFilter<VisitDB>().nested('c_order').property('docStatus').doesNotEqual(DocumentStatus.VOIDED).up());
		const { Results, PagingInfo } = (
			await graphqlClient.query({
				query: Bh_VisitForVisitHistoryInitialDocument,
				variables: {
					Size: initialVisitCountToFetch,
					Sort: JSON.stringify([['bh_visitdate', 'desc']]),
					Filter: filter.toString(),
				},
				fetchPolicy: 'network-only',
			})
		).data.BH_VisitGet;
		return {
			visits: Results,
			// Add one to the total count if this isn't a new visit (since we filtered out the current visit from the search)
			totalVisits: (PagingInfo.TotalCount || 0) + visitCountModifier,
		};
	}, [patientUU, initialVisitCountToFetch, visitUuid, graphqlClient]);

	const { data: patient } = useQuery(C_BPartnerForVisitHistoryDocument, {
		variables: { UU: patientUU! },
		fetchPolicy: 'cache-first',
	});

	return (
		<Modal show onHide={onClose} size="lg">
			<Modal.Header closeButton>
				{t(uiText.visit.MEDICAL_RECORDS, {
					patientName: patient?.C_BPartner?.Name,
					patientNumber: patient?.C_BPartner?.BH_Local_PatientID || patient?.C_BPartner?.BH_PatientID,
				})}
				<br />
				{t(uiText.visit.TOTAL_NUMBER_OF_VISITS, {
					totalVisits: areLoadingVisits ? t(uiText.visit.COUNTING) : patientVisitDetails?.totalVisits,
				})}
			</Modal.Header>
			<Modal.Body className="bh-form--review">
				{areLoadingVisits
					? t(uiText.visit.FETCHING_VISITS)
					: patientVisitDetails?.visits.map((visit) => (
							<VisitHistoryLine
								key={visit.UU}
								clinicians={clinicians}
								patientTypes={patientTypes}
								referrals={referrals}
								visitUU={visit.UU}
							/>
						))}
			</Modal.Body>
			<Modal.Footer>
				<button className="btn btn-secondary" onClick={onClose}>
					{t(uiText.visit.button.CLOSE)}
				</button>
			</Modal.Footer>
		</Modal>
	);
};

export default VisitHistory;
