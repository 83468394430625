import { ApolloClient, isApolloError, useApolloClient } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment, useContext, useState } from 'react';
import { Button, Card, Col, Dropdown, DropdownButton, Form, Modal, Row } from 'react-bootstrap';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAsyncFn } from 'react-use';
import { v4 } from 'uuid';
import FormModalContext from '../../contexts/FormModalContext';
import NavBlockingContext from '../../contexts/NavBlockingContext';
import UserContext from '../../contexts/UserContext';
import {
	Ad_ProcessGetForRunningReportsDocument,
	Ad_Ref_ListProcessStagesForVisitsDocument,
	Bh_Bp_Specific_Payer_InfoDeleteDocument,
	Bh_Bp_Specific_Payer_InfoSaveManyDocument,
	Bh_EncounterDeleteForVisitsDocument,
	Bh_EncounterSaveManyForVisitsDocument,
	Bh_Encounter_DiagnosisDeleteForVisitsDocument,
	Bh_Encounter_DiagnosisSaveManyForVisitsDocument,
	Bh_Encounter_DiagnosticDeleteForVisitsDocument,
	Bh_Encounter_DiagnosticSaveManyForVisitsDocument,
	Bh_Encounter_Type_WindowGetForVisitsDocument,
	Bh_ObservationDeleteForVisitsDocument,
	Bh_ObservationSaveManyForVisitsDocument,
	Bh_VisitDeleteDocument,
	Bh_VisitForEditingDocument,
	Bh_VisitForEditingQuery,
	Bh_VisitProcessDocument,
	Bh_VisitSaveDocument,
	C_BPartnerForVisitsDocument,
	C_InvoiceDeleteForVisitsDocument,
	C_InvoiceLineDeleteForVisitsDocument,
	C_InvoiceLineSaveManyForVisitsDocument,
	C_InvoiceSaveManyForVisitsDocument,
	C_OrderDeleteForVisitsDocument,
	C_OrderLineSaveManyForVisitsDocument,
	C_OrderSaveManyForVisitsDocument,
	C_PaymentDeleteForVisitsDocument,
	C_PaymentSaveManyForVisitsDocument,
	ProcessInfoParameterInput,
	ReportOutput,
} from '../../graphql/__generated__/graphql';
import useActionPrivileges from '../../hooks/useActionPrivileges';
import useConfirmRefresh from '../../hooks/useConfirmRefresh';
import useGraphQLIsDocumentActionAvailable from '../../hooks/useGraphQLIsDocumentActionAvailable';
import useSuspenseAsync from '../../hooks/useSuspenseAsync';
import useSuspenseGraphQLDocumentActionInformation from '../../hooks/useSuspenseGraphQLDocumentActionInformation';
import useSuspenseGraphQLProcessAccess from '../../hooks/useSuspenseGraphQLProcessAccess';
import { DBFilter, EncounterTypeWindowDB, ProcessDB, referenceUuids } from '../../models';
import DocAction from '../../models/DocAction';
import { documentBaseType } from '../../models/DocumentType';
import { ReferenceListDB } from '../../models/ReferenceList';
import {
	PATIENT_RECEIPT,
	PATIENT_VISIT_SUMMARY,
	VISIT_INVOICE,
	VISIT_INVOICE_PARAM_BH_VISIT_UU,
	VISIT_INVOICE_PARAM_SHOW_INSURANCE,
} from '../../models/Report';
import { pageUuid } from '../../services/AuthService';
import EntityFormProperties from '../../types/EntityFormProperties';
import { exception } from '../../utils/analytics';
import ConfirmAction from '../../utils/ConfirmAction';
import { REFRESH_PAGE, VISITS_PAGE } from '../../utils/Constants';
import { uiText } from '../../utils/Language';
import { getVisitNegativeInventoryErrorInformation } from '../../utils/ModelUtils';
import { generateReportWithGivenParameterValue, runAndExportReport } from '../../utils/ReportUtil';
import { isEntityCompleted, isEntityDrafted, isEntityReactivated, isEntityVoided } from '../../utils/StatusUtil';
import BasicButton from '../ActionButtons/BasicButton';
import BHDropdownButton from '../ActionButtons/BHDropdownButton';
import CustomPrompt from '../CustomPrompt/CustomPrompt';
import { withFormModalSuspenseWrapper } from '../HOCs/withFormModalSuspenseWrapper';
import Layout from '../Layout/Layout';
import LoadSpinner from '../LoadSpinner/LoadSpinner';
import SaveAndSendToModal from '../Modal/SaveAndSendToModal';
import VisitCompleteConfirmationModal from '../Modal/VisitCompleteConfirmationModal';
import VoidedReasonGraphQLModal from '../Modal/VoidedReasonGraphQLModal';
import ReceiptPrint from '../Reports/ReceiptPrint';
import ClinicalDetails, {
	ClinicalDetailsFormValues,
	constructClinicalDetailsFormDataToSave,
	convertToClinicalDetailsFormValues,
	primeClinicalDetailsData,
} from './ClinicalDetails';
import LabDiagnosticsDetails, {
	constructLabDiagnosticsDetailsFormDataToSave,
	convertToLabDiagnosticsDetailsFormValues,
	LabDiagnosticsDetailsFormValues,
	primeLabDiagnosticsDetailsData,
} from './LabDiagnosticsDetails';
import PaymentLineItemTable, {
	constructPaymentLineItemTableFormDataToSave,
	convertToPaymentLineItemTableFormValues,
	PaymentLineItemTableFormValues,
	primePaymentLineItemTableData,
} from './PaymentLineItemTable';
import ProductLineItemTable, {
	constructVisitProductLineItemTableFormDataToSave,
	convertToProductLineItemTableFormValues,
	primeVisitProductLineItemTableData,
	ProductLineItemTableFormValues,
} from './ProductLineItemTable';
import TriageDetails, {
	constructTriageDetailsFormDataToSave,
	convertToTriageDetailsFormFields,
	primeTriageDetailsData,
	TriageDetailsFormValues,
} from './TriageDetails';
import VisitDetailsEdit, {
	constructVisitDetailsFormDataToSave,
	convertToVisitDetailsFormFields,
	primeVisitDetailData,
	VisitDetailsFormValues,
} from './VisitDetailsEdit';
import VisitInfo from './VisitInfo';

export type VisitFormValues = {
	UU: string;
	BH_Process_Stage: { UU: string | null };
	C_Orders: Array<{ UU: string }>;
	isNew: 'true' | 'false';
	isVisitReactivated: boolean;
	submitEvent: '' | 'save' | 'complete';
} & VisitDetailsFormValues &
	TriageDetailsFormValues &
	ClinicalDetailsFormValues &
	LabDiagnosticsDetailsFormValues &
	ProductLineItemTableFormValues &
	PaymentLineItemTableFormValues;

export type VisitFormProps = {
	patientUU?: string;
} & EntityFormProperties;

const getTitle = (uuid?: string) => (uuid ? uiText.visit.UPDATE : uiText.visit.NEW);
export const convertToVisitFormFields: (
	graphqlClient: ApolloClient<object>,
	t: TFunction<'translation', undefined>,
	initialData?: Bh_VisitForEditingQuery['BH_Visit'],
	patientUU?: string,
) => VisitFormValues = (graphqlClient, t, initialData, patientUU) => {
	const generalData: Pick<VisitFormValues, 'submitEvent'> &
		VisitDetailsFormValues &
		TriageDetailsFormValues &
		ClinicalDetailsFormValues &
		LabDiagnosticsDetailsFormValues &
		ProductLineItemTableFormValues &
		PaymentLineItemTableFormValues = {
		submitEvent: '',
		...convertToVisitDetailsFormFields(initialData, patientUU),
		...convertToTriageDetailsFormFields(graphqlClient, initialData),
		...convertToClinicalDetailsFormValues(graphqlClient, t, initialData),
		...convertToLabDiagnosticsDetailsFormValues(graphqlClient, initialData),
		...convertToProductLineItemTableFormValues(initialData),
		...convertToPaymentLineItemTableFormValues(initialData),
	};
	if (!initialData) {
		return {
			...generalData,
			UU: v4(),
			BH_Process_Stage: { UU: null },
			isNew: 'true',
			isVisitReactivated: false,
		};
	}
	return {
		...generalData,
		UU: initialData.UU,
		BH_Process_Stage: { UU: initialData.BH_Process_Stage?.UU || null },
		isNew: 'false',
		isVisitReactivated: isEntityReactivated(initialData.C_Orders?.[0]),
	};
};

const VisitForm = ({ uuid, onFinish, patientUU, renderAsModal, canSaveMany }: VisitFormProps) => {
	const graphqlClient = useApolloClient();
	const { client, organization, warehouse } = useContext(UserContext);

	const { canView: canViewClinical, disableView: disableClinicalView } = useActionPrivileges(pageUuid.CLINICAL_DETAILS);
	const {
		canView: canViewTriage,
		disableView: disableTriageView,
		disableWrite: isDisabledTriageWrite,
	} = useActionPrivileges(pageUuid.TRIAGE_DETAILS);
	const {
		canView: canViewLab,
		disableView: disableLabView,
		disableWrite: isDisabledLabWrite,
	} = useActionPrivileges(pageUuid.LAB_DIAGNOSTICS_DETAILS);
	const { canView: canViewChiefComplaint } = useActionPrivileges(pageUuid.CHIEF_COMPLAINT);

	const { data: [initialData, , processStageList, visitInvoiceReportData] = [] } = useSuspenseAsync(
		uuid || 'visit-data-load',
		async () =>
			Promise.all([
				uuid
					? graphqlClient
							.query({ query: Bh_VisitForEditingDocument, variables: { UU: uuid }, fetchPolicy: 'network-only' })
							.then((response) => response.data.BH_Visit)
					: undefined,
				// This request will fail if the user doesn't have access to all windows, but we don't care
				graphqlClient
					.query({
						query: Bh_Encounter_Type_WindowGetForVisitsDocument,
						variables: {
							Filter: DBFilter<EncounterTypeWindowDB>()
								.nested('ad_window')
								.property('ad_window_uu')
								.isIn([
									...[
										canViewClinical ? pageUuid.CLINICAL_DETAILS : undefined,
										canViewTriage ? pageUuid.TRIAGE_DETAILS : undefined,
										canViewLab ? pageUuid.LAB_DIAGNOSTICS_DETAILS : undefined,
										canViewChiefComplaint ? pageUuid.CHIEF_COMPLAINT : undefined,
									].flatMap((pageUU) => (pageUU ? [pageUU] : [])),
								])
								.up()
								.toString(),
						},
						fetchPolicy: 'cache-first',
					})
					.catch((error) => {
						exception({ description: 'error fetching encounter type windows: ' + error });
					}),
				graphqlClient
					.query({
						query: Ad_Ref_ListProcessStagesForVisitsDocument,
						variables: {
							Filter: DBFilter<ReferenceListDB>()
								.nested('ad_reference')
								.property('ad_reference_uu')
								.equals(referenceUuids.PROCESS_STAGE)
								.up()
								.toString(),
						},
						fetchPolicy: 'cache-first',
					})
					.then((response) => response.data.AD_Ref_ListGet.Results),
				graphqlClient
					.query({
						query: Ad_ProcessGetForRunningReportsDocument,
						variables: { Filter: DBFilter<ProcessDB>().property('ad_process_uu').equals(VISIT_INVOICE).toString() },
						fetchPolicy: 'cache-first',
					})
					.then((response) => response.data.AD_ProcessGet.Results[0]),
				// Make sure to prime data for the patient in case we're supposed to start the visit with them selected
				!uuid && patientUU
					? graphqlClient.query({
							query: C_BPartnerForVisitsDocument,
							variables: { UU: patientUU },
							fetchPolicy: 'cache-first',
						})
					: undefined,
				primeVisitDetailData(graphqlClient),
				primeTriageDetailsData(),
				primeClinicalDetailsData(graphqlClient),
				primeLabDiagnosticsDetailsData(graphqlClient),
				primeVisitProductLineItemTableData(graphqlClient),
				primePaymentLineItemTableData(graphqlClient, organization),
			]),
	);

	const { t } = useTranslation();
	const { canView: canViewProducts } = useActionPrivileges(pageUuid.PRODUCTS);
	const { canView: canViewPayments } = useActionPrivileges(pageUuid.PAYMENTS);
	const { disableWrite } = useActionPrivileges(pageUuid.VISITS);
	const [processingErrorMessage, setProcessingErrorMessage] = useState<string[]>();
	const [viewVoidModal, setViewVoidModal] = useState(false);
	const [showCompleteModal, setShowCompleteModal] = useState(false);
	const [showSaveAndSendToModal, setShowSaveAndSendToModal] = useState(false);
	const history = useHistory();

	const [data, setData] = useState(initialData);
	const isDataReadOnly = isEntityCompleted(data?.C_Orders?.[0]) || isEntityVoided(data?.C_Orders?.[0]);
	const title = getTitle(data?.UU);

	const adjustPageTitle = () => {
		document.title = t(uiText.report.PATIENT_SUMMARY);
	};
	const resetPageTitle = () => {
		document.title = t(uiText.report.WEBSITE_TITLE);
		removeEventPrintListeners();
	};
	const addEventPrintListeners = () => {
		window.addEventListener('beforeprint', adjustPageTitle);
		window.addEventListener('afterprint', resetPageTitle);
	};
	const removeEventPrintListeners = () => {
		window.removeEventListener('beforeprint', adjustPageTitle);
		window.removeEventListener('afterprint', resetPageTitle);
	};

	const [canGenerateReceipt, canGeneratePatientVisitSummary, canPrintInvoice] = useSuspenseGraphQLProcessAccess([
		PATIENT_RECEIPT,
		PATIENT_VISIT_SUMMARY,
		VISIT_INVOICE,
	]);

	const [{ value: printReceiptUrl }, onPrintReport] = useAsyncFn(
		async (reportUuid: string, reportParameters?: ProcessInfoParameterInput[]) => {
			let blob: Blob | undefined;
			if (reportParameters) {
				blob = await runAndExportReport(graphqlClient, reportUuid, ReportOutput.Pdf, reportParameters);
			} else if (data) {
				// If no report parameters were specified, pass just a single parameter - data.current.uuid -
				// which is assigned the current bh_visit_uu, and have the function automatically assign
				// it to the first available parameter
				blob = await generateReportWithGivenParameterValue(graphqlClient, reportUuid, data.UU, ReportOutput.Pdf);
			}
			return blob ? URL.createObjectURL(blob) : '';
		},
		[graphqlClient],
	);

	const formMethods = useForm<VisitFormValues>({
		defaultValues: convertToVisitFormFields(graphqlClient, t, data, patientUU),
	});
	useConfirmRefresh(formMethods.formState?.isDirty && !isDataReadOnly);

	const { value: canReactivateVisit = false } = useGraphQLIsDocumentActionAvailable(
		documentBaseType.SalesOrder,
		data?.C_Orders?.[0].DocStatus.Value,
		DocAction.REACTIVATE,
	);
	const { canVoidDocument, voidDocumentAction } = useSuspenseGraphQLDocumentActionInformation(
		documentBaseType.SalesOrder,
		data?.C_Orders?.[0].DocStatus.Value,
	);

	const { toggleNavBlocking } = useContext(NavBlockingContext);

	const checkInvoiceHasInsurersOrDonors = () =>
		formMethods
			.getValues('paymentInformationList')
			.some((paymentInformation) => paymentInformation.isPayment !== 'true' && paymentInformation.isWaiver !== 'true');

	const reset = formMethods.reset;

	// We can't use the context because this form is finicky, so use a reference
	const { dataWasSaved, wasDataSaved } = useContext(FormModalContext);
	/**
	 * Validates and submits the visit via REST
	 * @param {Order} formData The data submitted from the visit form
	 */
	const [{ loading }, submitVisit] = useAsyncFn<SubmitHandler<VisitFormValues>>(
		async (formData) => {
			// check save/ complete operation
			let saveMutations: Promise<unknown>[] = [];
			let visit = constructVisitDetailsFormDataToSave(formData);
			saveMutations.push(graphqlClient.mutate({ mutation: Bh_VisitSaveDocument, variables: { BH_Visit: visit } }));

			// Get all encounters together
			const [
				{ save: triageDetailsEncounters, ignore: triageDetailsEncounterUUsToIgnore },
				{ save: triageDetailsEncounterObservations, ignore: triageDetailsEncounterObservationUUsToIgnore },
			] = constructTriageDetailsFormDataToSave(formData, graphqlClient, data);
			const [
				{ save: clincialDetailsEncounters, ignore: clincialDetailsEncounterUUsToIgnore },
				{ save: clinicalDetailsEncounterObservations, ignore: clinicalDetailsEncounterObservationUUsToIgnore },
				{ save: clinicalDetailsEncounterDiagnoses, ignore: clinicalDetailsEncounterDiagnosisUUsToIgnore },
			] = constructClinicalDetailsFormDataToSave(formData, data);
			const [
				{ save: labDiagnosticsDetailsEncounters, ignore: labDiagnosticsDetailsEncounterUUsToIgnore },
				{ save: labDiagnosticsEncounterObservations, ignore: labDiagnosticsEncounterObservationUUsToSave },
				{ save: labDiagnosticsEncounterDiagnostics, ignore: labDiagnosticsEncounterDiagnosticUUsToSave },
			] = constructLabDiagnosticsDetailsFormDataToSave(formData, data);

			const encountersToSave = [
				...triageDetailsEncounters,
				...clincialDetailsEncounters,
				...labDiagnosticsDetailsEncounters,
			];
			const encounterUUsToIgnore = [
				...triageDetailsEncounterUUsToIgnore,
				...clincialDetailsEncounterUUsToIgnore,
				...labDiagnosticsDetailsEncounterUUsToIgnore,
			];
			const observationsToSave = [
				...triageDetailsEncounterObservations,
				...clinicalDetailsEncounterObservations,
				...labDiagnosticsEncounterObservations,
			];
			const observationUUsToIgnore = [
				...triageDetailsEncounterObservationUUsToIgnore,
				...clinicalDetailsEncounterObservationUUsToIgnore,
				...labDiagnosticsEncounterObservationUUsToSave,
			];
			const encounterDiagnosisUUsToIgnore = [...clinicalDetailsEncounterDiagnosisUUsToIgnore];
			const encounterDiagnosticUUsToIgnore = [...labDiagnosticsEncounterDiagnosticUUsToSave];

			const encounterUUsToSave = encountersToSave.map((encounter) => encounter.UU || v4());
			let observationUUsToDelete: string[] = [];
			const observationUUsToSave = observationsToSave.map((observation) => observation.UU || v4());
			const encounterDiagnosisUUToSave = clinicalDetailsEncounterDiagnoses.map(
				(encounterDiagnosis) => encounterDiagnosis.UU || v4(),
			);
			let encounterDiagnosisUUsToDelete: string[] = [];
			const encounterDiagnosticUUsToSave = labDiagnosticsEncounterDiagnostics.map(
				(encounterDiagnostic) => encounterDiagnostic.UU || v4(),
			);
			let encounterDiagnosticUUsToDelete: string[] = [];
			let encounterUUsToDelete =
				data?.BH_Encounters?.filter(
					(encounter) => !encounterUUsToSave.includes(encounter.UU) && !encounterUUsToIgnore.includes(encounter.UU),
				).map((encounter) => {
					observationUUsToDelete.push(
						...(encounter.BH_Observations?.filter(
							(observation) =>
								!observationUUsToSave.includes(observation.UU) && !observationUUsToIgnore.includes(observation.UU),
						).map((observation) => {
							return observation.UU;
						}) || []),
					);
					encounterDiagnosisUUsToDelete.push(
						...(encounter.BH_Encounter_DiagnosisList?.filter(
							(encounterDiagnosis) =>
								!encounterDiagnosisUUToSave.includes(encounterDiagnosis.UU) &&
								!encounterDiagnosisUUsToIgnore.includes(encounterDiagnosis.UU),
						).map((encounterDiagnosis) => {
							return encounterDiagnosis.UU;
						}) || []),
					);
					encounterDiagnosticUUsToDelete.push(
						...(encounter.BH_Encounter_DiagnosticList?.filter(
							(encounterDiagnostic) =>
								!encounterDiagnosticUUsToSave.includes(encounterDiagnostic.UU) &&
								!encounterDiagnosticUUsToIgnore.includes(encounterDiagnostic.UU),
						).map((encounterDiagnostic) => {
							return encounterDiagnostic.UU;
						}) || []),
					);
					return encounter.UU;
				}) || [];
			observationUUsToDelete.push(
				...(data?.BH_Encounters?.flatMap((encounter) => encounter.BH_Observations || [])
					.filter(
						(observation) =>
							!observationUUsToSave.includes(observation.UU) && !observationUUsToIgnore.includes(observation.UU),
					)
					.map((observation) => observation.UU) || []),
			);
			encounterDiagnosisUUsToDelete.push(
				...(data?.BH_Encounters?.flatMap((encounter) => encounter.BH_Encounter_DiagnosisList || [])
					.filter(
						(encounterDiagnosis) =>
							!encounterDiagnosisUUToSave.includes(encounterDiagnosis.UU) &&
							!encounterDiagnosisUUsToIgnore.includes(encounterDiagnosis.UU),
					)
					.map((encounterDiagnosis) => encounterDiagnosis.UU) || []),
			);
			encounterDiagnosticUUsToDelete.push(
				...(data?.BH_Encounters?.flatMap((encounter) => encounter.BH_Encounter_DiagnosticList || [])
					.filter(
						(encounterDiagnostic) =>
							!encounterDiagnosticUUsToSave.includes(encounterDiagnostic.UU) &&
							!encounterDiagnosticUUsToIgnore.includes(encounterDiagnostic.UU),
					)
					.map((encounterDiagnostic) => encounterDiagnostic.UU) || []),
			);
			// Make sure child entites are unique
			observationUUsToDelete = [...new Set(observationUUsToDelete)];
			encounterDiagnosisUUsToDelete = [...new Set(encounterDiagnosisUUsToDelete)];
			encounterDiagnosticUUsToDelete = [...new Set(encounterDiagnosticUUsToDelete)];

			const [orders, orderLines] = await constructVisitProductLineItemTableFormDataToSave(
				formData,
				data,
				graphqlClient,
				warehouse,
			);
			const [
				{ save: invoicesToSave, delete: invoiceUUsToDelete },
				{ save: invoiceLinesToSave, delete: invoiceLineUUsToDelete },
				{
					save: businessPartnerSpecificPayerInformationToSave,
					delete: businessPartnerSpecificPayerInformationUUsToDelete,
				},
				{ save: paymentsToSave, delete: paymentUUsToDelete },
			] = await constructPaymentLineItemTableFormDataToSave(
				formData,
				data,
				graphqlClient,
				orders[0],
				orderLines,
				organization,
			);

			saveMutations.push(
				...[
					encountersToSave.length
						? graphqlClient.mutate({
								mutation: Bh_EncounterSaveManyForVisitsDocument,
								variables: { BH_Encounters: encountersToSave },
							})
						: Promise.resolve(),
					observationsToSave.length
						? graphqlClient.mutate({
								mutation: Bh_ObservationSaveManyForVisitsDocument,
								variables: { BH_Observations: observationsToSave },
							})
						: Promise.resolve(),
					clinicalDetailsEncounterDiagnoses.length
						? graphqlClient.mutate({
								mutation: Bh_Encounter_DiagnosisSaveManyForVisitsDocument,
								variables: { BH_Encounter_Diagnoses: clinicalDetailsEncounterDiagnoses },
							})
						: Promise.resolve(),
					labDiagnosticsEncounterDiagnostics.length
						? graphqlClient.mutate({
								mutation: Bh_Encounter_DiagnosticSaveManyForVisitsDocument,
								variables: { BH_Encounter_Diagnostics: labDiagnosticsEncounterDiagnostics },
							})
						: Promise.resolve(),
					graphqlClient.mutate({ mutation: C_OrderSaveManyForVisitsDocument, variables: { C_Orders: orders } }),
					graphqlClient.mutate({
						mutation: C_OrderLineSaveManyForVisitsDocument,
						variables: { C_OrderLines: orderLines },
					}),
					graphqlClient.mutate({
						mutation: C_InvoiceSaveManyForVisitsDocument,
						variables: { C_Invoices: invoicesToSave },
					}),
					graphqlClient.mutate({
						mutation: C_InvoiceLineSaveManyForVisitsDocument,
						variables: { C_InvoiceLines: invoiceLinesToSave },
					}),
					businessPartnerSpecificPayerInformationToSave.length
						? graphqlClient.mutate({
								mutation: Bh_Bp_Specific_Payer_InfoSaveManyDocument,
								variables: { BH_BP_Specific_Payer_InfoList: businessPartnerSpecificPayerInformationToSave },
							})
						: Promise.resolve(),
					paymentsToSave.length
						? graphqlClient.mutate({
								mutation: C_PaymentSaveManyForVisitsDocument,
								variables: { C_Payments: paymentsToSave },
							})
						: Promise.resolve(),
					paymentUUsToDelete.length
						? graphqlClient.mutate({
								mutation: C_PaymentDeleteForVisitsDocument,
								variables: { UUs: paymentUUsToDelete },
							})
						: Promise.resolve(),
					businessPartnerSpecificPayerInformationUUsToDelete.length
						? graphqlClient.mutate({
								mutation: Bh_Bp_Specific_Payer_InfoDeleteDocument,
								variables: { BH_BP_Specific_Payer_InfoUUs: businessPartnerSpecificPayerInformationUUsToDelete },
							})
						: Promise.resolve(),
					invoiceLineUUsToDelete.length
						? graphqlClient.mutate({
								mutation: C_InvoiceLineDeleteForVisitsDocument,
								variables: { UUs: invoiceLineUUsToDelete },
							})
						: Promise.resolve(),
					invoiceUUsToDelete.length
						? graphqlClient.mutate({
								mutation: C_InvoiceDeleteForVisitsDocument,
								variables: { UUs: invoiceUUsToDelete },
							})
						: Promise.resolve(),
					observationUUsToDelete.length
						? graphqlClient.mutate({
								mutation: Bh_ObservationDeleteForVisitsDocument,
								variables: { UUs: observationUUsToDelete },
							})
						: Promise.resolve(),
					encounterDiagnosisUUsToDelete.length
						? graphqlClient.mutate({
								mutation: Bh_Encounter_DiagnosisDeleteForVisitsDocument,
								variables: { UUs: encounterDiagnosisUUsToDelete },
							})
						: Promise.resolve(),
					encounterDiagnosticUUsToDelete.length
						? graphqlClient.mutate({
								mutation: Bh_Encounter_DiagnosticDeleteForVisitsDocument,
								variables: { UUs: encounterDiagnosticUUsToDelete },
							})
						: Promise.resolve(),
					encounterUUsToDelete.length
						? graphqlClient.mutate({
								mutation: Bh_EncounterDeleteForVisitsDocument,
								variables: { UUs: encounterUUsToDelete },
							})
						: Promise.resolve(),
				],
			);

			try {
				await Promise.all(saveMutations);
			} catch (error) {
				let stringError = '';
				if (error instanceof Error && isApolloError(error)) {
					stringError = error.graphQLErrors
						.map((graphqlError) => graphqlError.message.split(' : ')[1] || graphqlError.message)
						.join(', ');
				}
				// If the visit was saved, try to get it (mainly so we don't send new invoices on re-save and use existing, if there are any)
				const refetchedData = (
					await graphqlClient.query({
						query: Bh_VisitForEditingDocument,
						variables: { UU: formData.UU },
						fetchPolicy: 'network-only',
					})
				).data.BH_Visit;
				if (refetchedData) {
					dataWasSaved(formData.UU);
					setData(refetchedData);
				}
				exception({ description: `Visit save error: ${stringError}` });
				toast.error(t(uiText.visit.error.SAVE_ERROR, { error: stringError }));
				return;
			}

			// check save/ complete operation
			if (formData.submitEvent === 'complete') {
				try {
					await graphqlClient.mutate({
						mutation: Bh_VisitProcessDocument,
						variables: {
							UU: formData.UU,
							DocumentAction: DocAction.COMPLETE,
						},
					});
				} catch (error) {
					let stringError = '';
					if (error instanceof Error && isApolloError(error)) {
						let transformedMessage = [error.graphQLErrors[0].message];
						const negativeInventoryInformation = getVisitNegativeInventoryErrorInformation(
							error.graphQLErrors[0].message,
						);
						if (negativeInventoryInformation.length) {
							transformedMessage = [
								t(uiText.visit.NEGATIVE_INVENTORY_DISALLOWED),
								'',
								...negativeInventoryInformation.map(({ productName, inventoryShortageAmount }) =>
									t(uiText.visit.SELLING_MORE_OF_PRODUCT_THAN_AVAILABLE_QUANTITY, {
										productName,
										inventoryShortageAmount,
									}),
								),
							];
							const refetchedData = (
								await graphqlClient.query({
									query: Bh_VisitForEditingDocument,
									variables: { UU: formData.UU },
									fetchPolicy: 'network-only',
								})
							).data.BH_Visit;
							setProcessingErrorMessage(transformedMessage);
							dataWasSaved(formData.UU);
							setData(refetchedData);
							reset({}, { keepValues: true });
							return;
						}
						stringError = error.graphQLErrors
							.map((graphqlError) => graphqlError.message.split(' : ')[1] || graphqlError.message)
							.join(', ');
					}
					exception({ description: `Visit save error: ${stringError}` });
					toast.error(t(uiText.visit.error.UNABLE_TO_PROCESS, { error: stringError }));
					return;
				}
			}

			dataWasSaved(formData.UU);
			const refetchedData = (
				await graphqlClient.query({
					query: Bh_VisitForEditingDocument,
					variables: { UU: formData.UU },
					fetchPolicy: 'network-only',
				})
			).data.BH_Visit;
			setData(refetchedData);
			reset({}, { keepValues: true });
			toast.success(t(uiText.visit.SAVED));
		},
		[voidDocumentAction, warehouse, canSaveMany, graphqlClient],
	);
	const [, confirmPaymentsAndSubmit] = useAsyncFn<SubmitHandler<VisitFormValues>>(
		async (formData) => {
			// If there are no payments and we're submitting, confirm with the user
			if (!(formData.paymentInformationList || []).length && formData.submitEvent === 'complete') {
				if (!(await CustomPrompt(t(uiText.visit.prompt.COMPLETE_WITHOUT_PAYMENT)))) {
					return;
				}
			}
			return submitVisit(formData);
		},
		[submitVisit],
	);

	const [{ loading: areVoidingVisit }, voidVisit] = useAsyncFn(
		async (voidingReasonUU: string) => {
			try {
				await Promise.all([
					graphqlClient.mutate({
						mutation: Bh_VisitSaveDocument,
						variables: {
							BH_Visit: { UU: formMethods.getValues('UU'), BH_Voided_Reason: { UU: voidingReasonUU } },
						},
					}),
					graphqlClient.mutate({
						mutation: Bh_VisitProcessDocument,
						variables: {
							UU: formMethods.getValues('UU'),
							DocumentAction: voidDocumentAction || DocAction.VOID,
						},
					}),
				]);
				const refetchedData = (
					await graphqlClient.query({
						query: Bh_VisitForEditingDocument,
						variables: { UU: formMethods.getValues('UU') },
						fetchPolicy: 'network-only',
					})
				).data.BH_Visit;
				setData(refetchedData);
				reset({}, { keepValues: true });
				toast.success(t(uiText.visit.SAVED));
			} catch (error) {
				let stringError = '';
				if (error instanceof Error && isApolloError(error)) {
					stringError = error.graphQLErrors
						.map((graphqlError) => graphqlError.message.split(' : ')[1] || graphqlError.message)
						.join(', ');
				}
				console.error(t(uiText.visit.error.UNABLE_TO_PROCESS, { error: stringError }));
			}
		},
		[graphqlClient],
	);

	const setValue = formMethods.setValue;
	const [{ loading: areReactivatingVisit }, reactivateVisit] = useAsyncFn(async () => {
		try {
			await graphqlClient.mutate({
				mutation: Bh_VisitProcessDocument,
				variables: {
					UU: formMethods.getValues('UU'),
					DocumentAction: DocAction.REACTIVATE,
				},
			});
			const refetchedData = (
				await graphqlClient.query({
					query: Bh_VisitForEditingDocument,
					variables: { UU: formMethods.getValues('UU') },
					fetchPolicy: 'network-only',
				})
			).data.BH_Visit;
			setData(refetchedData);
			setValue('isVisitReactivated', true);
			reset({}, { keepValues: true });
			toast.success(t(uiText.visit.SAVED));
		} catch (error) {
			let stringError = '';
			if (error instanceof Error && isApolloError(error)) {
				stringError = error.graphQLErrors
					.map((graphqlError) => graphqlError.message.split(' : ')[1] || graphqlError.message)
					.join(', ');
			}
			console.error(t(uiText.visit.error.UNABLE_TO_PROCESS, { error: stringError }));
		}
	}, [graphqlClient, setValue]);

	const [{ loading: areDeletingVisit }, deleteVisit] = useAsyncFn(async () => {
		if (!data) {
			return;
		}
		let deletionMutations: Promise<unknown>[] = [];
		// If there are any business partner specific payer information, delete it
		if (
			data.C_Invoices?.some((invoice) =>
				invoice.C_InvoiceLines?.some((invoiceLine) => !!invoiceLine.BH_BP_Specific_Payer_InfoList?.length),
			)
		) {
			deletionMutations.push(
				graphqlClient.mutate({
					mutation: Bh_Bp_Specific_Payer_InfoDeleteDocument,
					variables: {
						BH_BP_Specific_Payer_InfoUUs: data.C_Invoices.flatMap((invoice) =>
							invoice.C_InvoiceLines?.flatMap((invoiceLine) =>
								invoiceLine.BH_BP_Specific_Payer_InfoList?.map(
									(businessPartnerSpecificPayerInformation) => businessPartnerSpecificPayerInformation.UU,
								),
							),
						).filter((UU) => !!UU) as string[],
					},
				}),
			);
		}
		// Delete any invoice and order lines
		if (data.C_Invoices?.some((invoice) => !!invoice.C_InvoiceLines?.length)) {
			deletionMutations.push(
				graphqlClient.mutate({
					mutation: C_InvoiceLineDeleteForVisitsDocument,
					variables: {
						UUs: data.C_Invoices.flatMap((invoice) => invoice.C_InvoiceLines || [])
							.map((invoiceLine) => invoiceLine.UU)
							.filter((UU) => !!UU) as string[],
					},
				}),
			);
		}
		if (data.C_Invoices?.length) {
			deletionMutations.push(
				graphqlClient.mutate({
					mutation: C_InvoiceDeleteForVisitsDocument,
					variables: { UUs: data.C_Invoices.map((invoice) => invoice.UU) },
				}),
			);
		}
		if (data.C_Orders?.some((order) => !!order.C_OrderLines?.length)) {
			deletionMutations.push(
				graphqlClient.mutate({
					mutation: C_InvoiceLineDeleteForVisitsDocument,
					variables: {
						UUs: data.C_Orders.flatMap((order) => order.C_OrderLines || [])
							.map((orderLine) => orderLine.UU)
							.filter((UU) => !!UU) as string[],
					},
				}),
			);
		}
		if (data.C_Orders?.length) {
			deletionMutations.push(
				graphqlClient.mutate({
					mutation: C_OrderDeleteForVisitsDocument,
					variables: { UUs: data.C_Orders.map((order) => order.UU) },
				}),
			);
		}
		// Delete any payments
		if (data.C_Payments?.length) {
			deletionMutations.push(
				graphqlClient.mutate({
					mutation: C_PaymentDeleteForVisitsDocument,
					variables: { UUs: data.C_Payments.map((payment) => payment.UU) },
				}),
			);
		}
		// Delete observations, encoutner diagnoses, encounter diagnostics, and encounters
		let observations = data.BH_Encounters?.flatMap((encounter) => encounter.BH_Observations || []).filter(
			(observation) => !!observation,
		);
		if (observations?.length) {
			deletionMutations.push(
				graphqlClient.mutate({
					mutation: Bh_ObservationDeleteForVisitsDocument,
					variables: { UUs: observations.map((observation) => observation.UU) },
				}),
			);
		}
		let encounterDiagnostics = data.BH_Encounters?.flatMap(
			(encounter) => encounter.BH_Encounter_DiagnosticList || [],
		).filter((encounterDiagnostic) => !!encounterDiagnostic);
		if (encounterDiagnostics?.length) {
			deletionMutations.push(
				graphqlClient.mutate({
					mutation: Bh_Encounter_DiagnosticDeleteForVisitsDocument,
					variables: { UUs: encounterDiagnostics.map((encounterDiagnostic) => encounterDiagnostic.UU) },
				}),
			);
		}
		let encounterDiagnoses = data.BH_Encounters?.flatMap(
			(encounter) => encounter.BH_Encounter_DiagnosisList || [],
		).filter((encounterDiagnosis) => !!encounterDiagnosis);
		if (encounterDiagnoses?.length) {
			deletionMutations.push(
				graphqlClient.mutate({
					mutation: Bh_Encounter_DiagnosisDeleteForVisitsDocument,
					variables: { UUs: encounterDiagnoses.map((encounterDiagnosis) => encounterDiagnosis.UU) },
				}),
			);
		}
		if (data.BH_Encounters?.length) {
			deletionMutations.push(
				graphqlClient.mutate({
					mutation: Bh_EncounterDeleteForVisitsDocument,
					variables: { UUs: data.BH_Encounters.map((encounter) => encounter.UU) },
				}),
			);
		}
		// Finally, delete the visit itself
		deletionMutations.push(graphqlClient.mutate({ mutation: Bh_VisitDeleteDocument, variables: { UUs: [data.UU] } }));
		Promise.all(deletionMutations)
			.then(() => {
				toggleNavBlocking(false);
				history.push(REFRESH_PAGE + VISITS_PAGE);
			})
			.catch((error) => {
				let stringError = '';
				if (error instanceof Error && isApolloError(error)) {
					stringError = error.graphQLErrors
						.map((graphqlError) => graphqlError.message.split(' : ')[1] || graphqlError.message)
						.join(', ');
				}
				console.error(t(uiText.visit.error.COULD_NOT_DELETE, { error: stringError }));
			});
	});

	const shouldShowProcessingIndicator = loading || areVoidingVisit || areDeletingVisit || areReactivatingVisit;

	const inputs = (
		<FormProvider {...formMethods}>
			<Form onSubmit={formMethods.handleSubmit(confirmPaymentsAndSubmit)} className="px-0">
				<input type="hidden" {...formMethods.register('UU')} />
				<input type="hidden" {...formMethods.register('C_Orders.0.UU')} />
				<h1 className="print">{client.name}</h1>
				<VisitInfo />
				<VisitDetailsEdit isDataReadOnly={isDataReadOnly} />

				<fieldset disabled={disableWrite || isDataReadOnly}>
					{!disableTriageView && <TriageDetails isDataReadOnly={isDataReadOnly || isDisabledTriageWrite} />}

					{!disableClinicalView && <ClinicalDetails isDataReadOnly={isDataReadOnly} />}

					{!disableLabView && <LabDiagnosticsDetails isDataReadOnly={isDataReadOnly || isDisabledLabWrite} />}

					{canViewProducts && (
						<Card className="bh-card">
							<Card.Header className="fw-bold h5">{t(uiText.visit.form.product.LABEL)}</Card.Header>
							<Card.Body>
								<ProductLineItemTable readOnly={isDataReadOnly} />
							</Card.Body>
						</Card>
					)}

					{canViewPayments && (
						<Card className="bh-card">
							<Card.Header className="fw-bold h5">{t(uiText.visit.form.payment.LABEL)}</Card.Header>
							<Card.Body>
								<PaymentLineItemTable readOnly={isDataReadOnly} isVisitFinished={isDataReadOnly} />
							</Card.Body>
						</Card>
					)}
				</fieldset>
				<input type="hidden" {...formMethods.register('submitEvent')} />
			</Form>
		</FormProvider>
	);

	const buttons = (
		<Row className={`${!renderAsModal ? 'm-4 ms-3' : ''}`} role="toolbar">
			<Col xs="auto" className="me-auto">
				{disableWrite ? (
					<BasicButton
						variant="danger"
						name={uiText.product.button.BACK}
						text={t(uiText.product.button.BACK)}
						icon="arrow-left"
						active
						onClick={() => {
							if (patientUU) {
								history.push(VISITS_PAGE);
							} else {
								wasDataSaved ? onFinish(true, data?.UU) : onFinish(false);
							}
						}}
					/>
				) : (
					<Button
						type="button"
						variant="danger"
						name="cancel"
						className="me-auto"
						onClick={() => {
							if (patientUU) {
								history.push(VISITS_PAGE);
							} else {
								wasDataSaved ? onFinish(true, data?.UU) : onFinish(false);
							}
						}}
					>
						{isDataReadOnly ? t(uiText.visit.button.BACK) : t(uiText.visit.button.CANCEL)}
					</Button>
				)}
			</Col>
			{!disableWrite && data && isEntityDrafted(data.C_Orders?.[0]) && !isEntityReactivated(data.C_Orders?.[0]) ? (
				<Col xs="auto">
					<Button
						type="button"
						variant="danger"
						onClick={() =>
							ConfirmAction(t(uiText.visit.DELETE_DRAFT_PROMPT), () => {
								deleteVisit();
							})
						}
					>
						{t(uiText.visit.button.DELETE)}
					</Button>
				</Col>
			) : null}

			{!disableWrite && (!data || !data.C_Orders || isEntityDrafted(data?.C_Orders?.[0])) ? (
				<>
					<Col xs="auto">
						<BHDropdownButton title={t(uiText.visit.button.SAVE)} variant="primary" icon="check">
							<Dropdown.Item
								as="button"
								onClick={() => {
									formMethods.setValue('submitEvent', 'save');
									formMethods.handleSubmit(submitVisit)();
								}}
							>
								<FontAwesomeIcon icon="save" className="me-2 fa-fw" />
								{t(uiText.visit.button.SAVE_BILL)}
							</Dropdown.Item>
							<Dropdown.Item
								as="button"
								type="button"
								onClick={() => {
									formMethods.setValue('submitEvent', 'save');
									setShowSaveAndSendToModal(true);
								}}
							>
								<FontAwesomeIcon icon="save" className="me-2 fa-fw" />
								{t(uiText.visit.button.SAVE_AND_SEND_TO)}
							</Dropdown.Item>
						</BHDropdownButton>
					</Col>
					{canViewPayments && canViewProducts ? (
						<Col xs="auto">
							<Button
								name="button"
								type="button"
								variant="success"
								onClick={() => {
									setShowCompleteModal(true);
								}}
							>
								{t(uiText.visit.button.COMPLETE)}
							</Button>
						</Col>
					) : null}
				</>
			) : null}

			{isEntityCompleted(data?.C_Orders?.[0]) ? (
				<>
					{!disableWrite && (canVoidDocument || canReactivateVisit) ? (
						<Col xs="auto">
							<DropdownButton title={t(uiText.visit.button.ACTION)} variant="danger">
								{canReactivateVisit && (
									<Dropdown.Item
										as="button"
										onClick={() => {
											reactivateVisit();
										}}
									>
										{t(uiText.visit.button.REACTIVATE_BILL)}
									</Dropdown.Item>
								)}
								{canVoidDocument && (
									<Dropdown.Item
										as="button"
										type="button"
										onClick={() => {
											setViewVoidModal(true);
										}}
									>
										{t(uiText.visit.button.VOID_BILL)}
									</Dropdown.Item>
								)}
							</DropdownButton>
						</Col>
					) : null}

					{[canPrintInvoice, canGeneratePatientVisitSummary, canGenerateReceipt].some((access) => access) && (
						<Col xs="auto">
							<DropdownButton title={t(uiText.visit.button.PRINT)} variant="primary">
								{canGenerateReceipt && (
									<Dropdown.Item as="button" type="button" onClick={() => onPrintReport(PATIENT_RECEIPT)}>
										{t(uiText.visit.button.RECEIPT)}
									</Dropdown.Item>
								)}
								{!renderAsModal && canGeneratePatientVisitSummary && (
									<Dropdown.Item
										as="button"
										type="button"
										onClick={() => {
											addEventPrintListeners();
											window.print();
										}}
									>
										{t(uiText.visit.button.PATIENT_SUMMARY)}
									</Dropdown.Item>
								)}
								{canPrintInvoice && data && visitInvoiceReportData && (
									<Dropdown.Item
										as="button"
										type="button"
										onClick={() =>
											onPrintReport(VISIT_INVOICE, [
												{
													AD_Process: { UU: VISIT_INVOICE },
													Parameter: data.UU,
													ParameterName: visitInvoiceReportData.AD_Process_ParaList?.find(
														(processParameter) => processParameter.UU === VISIT_INVOICE_PARAM_BH_VISIT_UU,
													)?.Name!,
												},
												{
													AD_Process: { UU: VISIT_INVOICE },
													Parameter: false,
													ParameterName: visitInvoiceReportData.AD_Process_ParaList?.find(
														(processParameter) => processParameter.UU === VISIT_INVOICE_PARAM_SHOW_INSURANCE,
													)?.Name!,
												},
											])
										}
									>
										{t(uiText.visit.button.PATIENT_INVOICE)}
									</Dropdown.Item>
								)}
								{canPrintInvoice && data && visitInvoiceReportData && checkInvoiceHasInsurersOrDonors() ? (
									<Dropdown.Item
										as="button"
										type="button"
										onClick={() =>
											onPrintReport(VISIT_INVOICE, [
												{
													AD_Process: { UU: VISIT_INVOICE },
													Parameter: data.UU,
													ParameterName: visitInvoiceReportData.AD_Process_ParaList?.find(
														(processParameter) => processParameter.UU === VISIT_INVOICE_PARAM_BH_VISIT_UU,
													)?.Name!,
												},
												{
													AD_Process: { UU: VISIT_INVOICE },
													Parameter: false,
													ParameterName: visitInvoiceReportData.AD_Process_ParaList?.find(
														(processParameter) => processParameter.UU === VISIT_INVOICE_PARAM_SHOW_INSURANCE,
													)?.Name!,
												},
											])
										}
									>
										{t(uiText.visit.button.INSURER_INVOICE)}
									</Dropdown.Item>
								) : null}
							</DropdownButton>
						</Col>
					)}

					{!disableWrite && canSaveMany !== false ? (
						<Col xs="auto">
							<Button
								type="button"
								variant="success"
								onClick={() => {
									setData(undefined);
									reset(convertToVisitFormFields(graphqlClient, t));
								}}
							>
								{t(uiText.visit.button.ADD_NEW_VISIT)}
							</Button>
						</Col>
					) : null}
				</>
			) : null}
		</Row>
	);

	return (
		<>
			{printReceiptUrl && <ReceiptPrint id={'receipt'} url={printReceiptUrl} />}
			{viewVoidModal && (
				<VoidedReasonGraphQLModal
					windowUuid={pageUuid.VISITS}
					onHandleClose={() => {
						setViewVoidModal(false);
					}}
					onVoidSubmit={({ BH_Voided_Reason }) => {
						voidVisit(BH_Voided_Reason.UU);
						setViewVoidModal(false);
					}}
				/>
			)}
			{showCompleteModal && (
				<VisitCompleteConfirmationModal
					cancel={() => {
						setShowCompleteModal(false);
					}}
					confirm={() => {
						setShowCompleteModal(false);
						formMethods.setValue('submitEvent', 'complete');
						formMethods.handleSubmit(confirmPaymentsAndSubmit)();
					}}
				/>
			)}
			{showSaveAndSendToModal && processStageList && (
				<SaveAndSendToModal
					processStageList={processStageList}
					onHandleClose={() => {
						setShowSaveAndSendToModal(false);
					}}
					processStageUU={formMethods.getValues('BH_Process_Stage.UU')}
					onHandleSubmit={(newProcessStageUU) => {
						formMethods.setValue('BH_Process_Stage.UU', newProcessStageUU || null);
						formMethods.handleSubmit(confirmPaymentsAndSubmit)();
						setShowSaveAndSendToModal(false);
					}}
				/>
			)}
			<Modal show={!!processingErrorMessage && processingErrorMessage.length > 0}>
				<Modal.Header>
					<Modal.Title>{t(uiText.document.COULD_NOT_PROCESS_TRY_AGAIN)}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{t(uiText.error.FOLLOWING_ERROR_OCCURRED)}
					<em className="text-gray-500">
						{processingErrorMessage?.map((message, index) => (
							<Fragment key={index}>
								<br />
								{message}
							</Fragment>
						))}
					</em>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="danger"
						onClick={() => {
							setProcessingErrorMessage(undefined);
						}}
					>
						{t(uiText.modal.OK)}
					</Button>
				</Modal.Footer>
			</Modal>
			{renderAsModal ? (
				<>
					<Modal.Header closeButton>
						<Modal.Title>{t(title)}</Modal.Title>
					</Modal.Header>
					{shouldShowProcessingIndicator && <LoadSpinner inline title={t(uiText.visit.PROCESSING)} />}
					<Modal.Body
						hidden={shouldShowProcessingIndicator}
						className={`${shouldShowProcessingIndicator ? '' : 'pt-0'}`}
					>
						{inputs}
					</Modal.Body>
					{!shouldShowProcessingIndicator ? (
						<Modal.Footer>
							<div className="w-100">{buttons}</div>
						</Modal.Footer>
					) : null}
				</>
			) : (
				<>
					<Layout.Header>
						<Layout.Title title={t(title)} />
						<Layout.Menu />
					</Layout.Header>
					<Layout.Body>
						{shouldShowProcessingIndicator && <LoadSpinner title={t(uiText.visit.PROCESSING)} />}
						<div hidden={shouldShowProcessingIndicator} className="bg-white pb-0_5 me-n2_5">
							{inputs}
							{buttons}
						</div>
					</Layout.Body>
				</>
			)}
		</>
	);
};

export default withFormModalSuspenseWrapper<VisitFormProps>({ loadingLabel: uiText.visit.FETCHING, getTitle })(
	VisitForm,
);
