import { ApolloClient, useApolloClient } from '@apollo/client';
import { useContext, useEffect, useMemo, useRef } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import UserContext from '../../contexts/UserContext';
import {
	Bh_VisitForEditingQuery,
	C_DocTypeForFormsDocument,
	C_OrderInput,
	C_OrderLineInput,
	M_ProductDisplayForVisitsFragmentDoc,
} from '../../graphql/__generated__/graphql';
import { documentBaseType, documentSubTypeSalesOrder, OrderLine, Product, Warehouse } from '../../models';
import Nullable from '../../types/Nullable';
import { uiText } from '../../utils/Language';
import { getDocumentBaseTypeFilter } from '../../utils/ModelUtils';
import ProductLineItemTableFooter from './ProductLineItemTableFooter';
import ProductLineItemTableRow from './ProductLineItemTableRow';
import { VisitFormValues } from './VisitForm';

export type ProductLineItemTableFormValues = {
	C_Orders: Array<{
		UU: string;
		C_OrderLines: Array<{
			UU: string;
			Description: string | null;
			M_Product: { UU: string };
			PriceEntered: number | null;
			QtyEntered: number | null;
			isOrderLineNew: boolean;
			sellingTooMuch: string;
		}>;
	}>;
	addNewOrderLine: {
		Description: string | null;
		M_Product: { UU: string };
		PriceEntered: number | null;
		QtyEntered: number | null;
	};
	orderLineCount: number;
};

export const convertToProductLineItemTableFormValues: (
	initialData?: Bh_VisitForEditingQuery['BH_Visit'],
) => ProductLineItemTableFormValues = (initialData) => {
	let commonData: Pick<ProductLineItemTableFormValues, 'addNewOrderLine'> = {
		addNewOrderLine: { Description: null, M_Product: { UU: '' }, PriceEntered: null, QtyEntered: null },
	};
	const emptyOrders: ProductLineItemTableFormValues['C_Orders'] = [{ UU: v4(), C_OrderLines: [] }];
	if (!initialData) {
		return {
			C_Orders: emptyOrders,
			orderLineCount: 0,
			...commonData,
		};
	}
	return {
		C_Orders:
			initialData.C_Orders?.map((order) => ({
				UU: order.UU,
				C_OrderLines:
					order.C_OrderLines?.map((orderLine) => ({
						UU: orderLine.UU,
						Description: orderLine.Description || null,
						M_Product: { UU: orderLine.M_Product?.UU || '' },
						PriceEntered: orderLine.PriceEntered,
						QtyEntered: orderLine.QtyEntered,
						isOrderLineNew: false,
						sellingTooMuch: '0',
					})) || [],
			})) || emptyOrders,
		orderLineCount: initialData.C_Orders?.flatMap((order) => order.C_OrderLines || []).length || 0,
		...commonData,
	};
};
export const constructVisitProductLineItemTableFormDataToSave = async (
	data: ProductLineItemTableFormValues & { UU: string; BH_VisitDate: Date; Patient: { UU: string | null } },
	initialData: Bh_VisitForEditingQuery['BH_Visit'],
	graphqlClient: ApolloClient<object>,
	warehouse: Warehouse,
): Promise<[C_OrderInput[], C_OrderLineInput[]]> => {
	let warehouseOrderDocumentType = (
		await graphqlClient.query({
			query: C_DocTypeForFormsDocument,
			variables: { Filter: getDocumentBaseTypeFilter(...fetchWarehouseOrderDocumentTypeArguments).toString() },
			fetchPolicy: 'cache-first',
		})
	).data.C_DocTypeGet.Results[0];
	let order: C_OrderInput = {
		UU: initialData?.C_Orders?.[0].UU || data.C_Orders[0].UU,
		BH_Visit: { UU: data.UU },
		C_BPartner: { UU: data.Patient.UU! },
		C_DocTypeTarget: { UU: warehouseOrderDocumentType.UU },
		DateOrdered: data.BH_VisitDate.getTime(),
		IsSOTrx: warehouseOrderDocumentType.IsSOTrx,
		M_Warehouse: { UU: warehouse.uuid },
	};
	let orderLines: C_OrderLineInput[] = data.C_Orders[0].C_OrderLines.map((orderLine) => ({
		UU: orderLine.UU,
		C_Order: { UU: order.UU! },
		Description: orderLine.Description,
		M_Product: { UU: orderLine.M_Product.UU },
		Price: orderLine.PriceEntered,
		Qty: orderLine.QtyEntered,
	}));
	return [[order], orderLines];
};

const fetchWarehouseOrderDocumentTypeArguments = [
	documentBaseType.SalesOrder,
	documentSubTypeSalesOrder.WarehouseOrder,
	null,
	true,
	false,
	false,
] as const;
export const primeVisitProductLineItemTableData = (graphqlClient: ApolloClient<object>) =>
	graphqlClient.query({
		query: C_DocTypeForFormsDocument,
		variables: { Filter: getDocumentBaseTypeFilter(...fetchWarehouseOrderDocumentTypeArguments).toString() },
		fetchPolicy: 'cache-first',
	});

type ProductLineItemTableProps = {
	readOnly: boolean;
};

const ProductLineItemTable = ({ readOnly }: ProductLineItemTableProps) => {
	const { t } = useTranslation();
	const graphqlClient = useApolloClient();
	const {
		register,
		setFocus,
		setValue,
		getValues,
		formState: { errors },
	} = useFormContext<VisitFormValues>();
	const { fields, append, remove } = useFieldArray<ProductLineItemTableFormValues, 'C_Orders.0.C_OrderLines', 'UU'>({
		name: 'C_Orders.0.C_OrderLines',
		keyName: 'UU',
	});
	const addNewOrderLine = useWatch<ProductLineItemTableFormValues, 'addNewOrderLine'>({ name: 'addNewOrderLine' });
	const shouldDisplayTheDeleteColumn = !readOnly;
	const shouldFocusOnLastField = useRef(false);
	const { warehouse } = useContext(UserContext);
	const locator = useMemo(() => warehouse?.locators.find((locator) => locator.isDefault), [warehouse]);

	// This handles adding a new row if the user started typing a new value
	useEffect(() => {
		if (addNewOrderLine.M_Product.UU) {
			append({
				UU: v4(),
				Description: addNewOrderLine.Description,
				M_Product: { UU: addNewOrderLine.M_Product.UU },
				QtyEntered: addNewOrderLine.QtyEntered || 1,
				PriceEntered: graphqlClient.readFragment({
					id: addNewOrderLine.M_Product.UU,
					fragment: M_ProductDisplayForVisitsFragmentDoc,
				})?.BH_SellPrice,
				isOrderLineNew: true,
				sellingTooMuch: '0',
			});
			// Reset the other fields
			const newOrderLine: Nullable<OrderLine> = new OrderLine({ product: new Product() });
			newOrderLine.price = null;
			newOrderLine.quantity = null;
			setValue('addNewOrderLine', { Description: null, M_Product: { UU: '' }, PriceEntered: null, QtyEntered: null });
			shouldFocusOnLastField.current = true;
		}
	}, [addNewOrderLine, append, setValue, graphqlClient]);
	// If there are fields and we should focus on the last one, do so
	useEffect(() => {
		if (fields.length && shouldFocusOnLastField.current) {
			setFocus(`C_Orders.0.C_OrderLines.${fields.length - 1}.Description`);
			shouldFocusOnLastField.current = false;
		}
	}, [fields.length, setFocus, shouldFocusOnLastField]);
	useEffect(() => {
		setValue('orderLineCount', fields.length);
	}, [fields.length, setValue]);

	return (
		<div className="table-responsive">
			<input
				type="hidden"
				{...register('orderLineCount', {
					valueAsNumber: true,
					validate: (value: number) => (getValues('submitEvent') === 'complete' ? value > 0 : true),
				})}
			/>
			<input type="hidden" {...register('C_Orders.0.UU')} />
			<table className="table bh-table--form">
				<thead>
					<tr>
						<th className="data-type-autocomplete">{t(uiText.visit.form.product.table.PRODUCT_OR_SERVICE)}</th>
						<th className="data-type-text">{t(uiText.visit.form.product.table.INSTRUCTIONS)}</th>
						<th className="data-type-numeric">{t(uiText.visit.form.product.table.EXISTING_QUANTITY)}</th>
						<th className="data-type-numeric">{t(uiText.visit.form.product.table.QUANTITY)}</th>
						<th className="data-type-numeric">{t(uiText.visit.form.product.table.UNIT_SELL_PRICE)}</th>
						<th className="data-type-numeric">{t(uiText.visit.form.product.table.TOTAL)}</th>
						{shouldDisplayTheDeleteColumn ? <th>{t(uiText.visit.form.product.DELETE)}</th> : null}
					</tr>
				</thead>
				<tbody>
					{fields.map((orderLine, index) => (
						<ProductLineItemTableRow
							key={orderLine.UU}
							field={orderLine}
							index={index}
							remove={remove}
							shouldDisplayTheDeleteColumn={shouldDisplayTheDeleteColumn}
							isDataReadOnly={readOnly}
							locator={locator}
						/>
					))}
				</tbody>
				<tbody>
					{!readOnly && (
						<ProductLineItemTableRow
							shouldDisplayTheDeleteColumn={shouldDisplayTheDeleteColumn}
							isAddRow={true}
							locator={locator}
						/>
					)}
				</tbody>
				<tbody>
					<ProductLineItemTableFooter shouldDisplayTheDeleteColumn={shouldDisplayTheDeleteColumn} />
				</tbody>
			</table>
			{errors.C_Orders?.[0]?.C_OrderLines?.some((orderLineError) => !!orderLineError?.QtyEntered) && (
				<div className="text-danger">{t(uiText.order.error.PRODUCT_MISSING_QUANTITY_GENERIC)}</div>
			)}
			{errors.C_Orders?.[0]?.C_OrderLines?.some((orderLineError) => !!orderLineError?.sellingTooMuch) && (
				<div className="text-danger">{t(uiText.visit.prompt.PRODUCT_LINE_QUANTITY_EXCEEDS_INVENTORY)}</div>
			)}
			{errors.orderLineCount && <div className="text-danger">{t(uiText.order.error.MISSING_PRODUCT)}</div>}
		</div>
	);
};

export default ProductLineItemTable;
